<template>
  <div id="createShop">
    <form>
      <ul>
        <li>
          <label for="cName">{{ config.translation['customerName'] }}:</label><br>
          <input type="text" class="lsc-input lsc-rounded" id="cName" v-model="customer.name">
        </li>
        <li>
          <label for="cAddress">{{ config.translation['address'] }}:</label><br>
          <input type="text" class="lsc-input lsc-rounded" id="cAddress" v-model="customer.address">
        </li>
        <li>
          <label for="cZipCode">{{ config.translation['postalCode'] }}:</label><br>
          <input type="text" class="lsc-input lsc-rounded" id="cZipCode"
                 v-model.lazy="customer.postalCode" @change="suggestDistrict()">
        </li>
        <li>
          <label for="cCity">{{ config.translation['city'] }}:</label><br>
          <input type="text" class="lsc-input lsc-rounded" id="cCity" v-model="customer.city">
        </li>
        <li v-if="user.userRole === 'internal'">
          <label for="cPhone">{{ config.translation['phone'] }}:</label><br>
          <input type="text" class="lsc-input lsc-rounded" id="cPhone" v-model="customer.phone"
                 :placeholder="config.translation['optionalDots']">
        </li>
        <li>
          <label for="cComment">{{ config.translation['optionalComment'] }}:</label><br>
          <textarea class="lsc-input lsc-rounded" id="cComment"
                    :placeholder="config.translation['optionalDots']"></textarea>
        </li>
        <li v-if="user.userRole === 'internal'">
          <select class="lsc-input lsc-rounded" id="cDistrict" v-model="customer.districtId"
                  :title="config.translation['district']">
            <option value="NA">{{ config.translation['dashChooseDistrictDash'] }}</option>
            <option v-for="district in districts" :value="district.id" :key="district.id">
              {{district.name}}
            </option>
          </select>
        </li>
        <li v-if="user.userRole === 'internal'">
          <label>{{ x('filter') }}: <input class="lsc-input lsc-rounded chain-filter" v-model="chainFilter"
                        type="text" :placeholder="x('filterChainList')"></label>
          <label><select class="lsc-input lsc-rounded" id="cChain" v-model="customer.chainId"
                         :title="config.translation['chain']"
                         :class="{ 'filtered-combobox': chainFilter !== ''}">
            <option value="NA">{{ config.translation['dashChooseChainDash'] }}</option>
            <option v-for="chain in filteredChains" :value="chain.id" :key="chain.id">
              {{chain.longName}}
            </option>
          </select></label>
          <p v-if="chainFilter.length > 0">{{x('chainsInTheList')}}: {{ filteredChains.length }}</p>
        </li>
        <li>
          <div class="missingData" v-if="errorMessage !== ''">
            <i class='fa fa-exclamation-circle'></i><br>{{ errorMessage }}
          </div>
          <button type="button" class="lsc-button lsc-rounded-10 red" @click="cancel()"><icon
            name="fa-close"/>{{ config.translation['cancel'] }}
          </button>
          <button type="button" class="lsc-button lsc-rounded-10 green right" @click="save()">
            <template v-if="!isSubmitting">
              <icon name="fa-upload"/>{{ config.translation['save'] }}
            </template>
            <template v-else>
              <i class='fa fa-cog fa-spin'></i>{{ config.translation['savingDots'] }}
            </template>
          </button>
        </li>
      </ul>
      <div class="lsc-clear"></div>
    </form>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { httpGet, httpPost } from '@/classes/httpHelper';
  import ComponentEventNames from '@/enums/component-event-names';
  import translate from '@/components/Mixins/Translate';

  export default {
    name: 'create-shop',
    emits: [
      ComponentEventNames.close,
      'created',
    ],
    mixins: [translate],
    data() {
      return {
        customer: this.createNewCustomer(),
        chains: [],
        districts: [],
        isSubmitting: false,
        errorMessage: '',
        chainFilter: '',
      };
    },
    computed: {
      filteredChains() {
        if (this.chainFilter.length === 0) return this.chains;
        return this.chains.filter((chain) => chain.longName
          .toLowerCase()
          .indexOf(this.chainFilter.toLowerCase()) !== -1);
      },
      ...mapState(['config', 'user', 'currentReactionType']),
      ...mapState('LocationStore', ['location']),
      ...mapState('SurveyStore', ['currentSurvey']),
    },
    watch: {
      location: {
        async handler(newValue) {
          if (newValue !== undefined && newValue !== null && 'latitude' in newValue) {
            await this.loadAddress(newValue);
          }
        },
        immediate: true,
      },
    },
    async mounted() {
      if (this.currentReactionType === 'survey') {
        // TODO: SurveyType bliver valgt inden Take Picture-skærmen åbnes.
        // Derfor skal man ikke bede serveren om dette.
        const surveyType = this.currentSurvey.surveyType;
        console.log(`Loading chains and districts for survey type ${surveyType}.`);
        this.chains = await httpGet(`chains/surveytype/${surveyType}`);
        this.districts = await httpGet(`districts/surveytype/${surveyType}`);
      } else {
        console.log('Loading all chains');
        try {
          this.chains = await httpGet('chains');
        } catch {
          console.error('CreateManualCustomer: Could not load chains. Setting default.');
          this.chains = [{
            id: 'OTHE',
            marketId: 'DK',
            name: 'Øvrige',
            owner: null,
            sortIndex: 100,
            source: 'Manual',
            inactiveDate: null,
            channelId: 'OFFT',
            email: null,
            referenceNo: 'OTHE',
            longName: 'Øvrige (OTHE)',
          }];
        }
        this.districts = await httpGet('districts');
      }

      setTimeout(() => this.suggestDistrict(), 1000);
    },
    methods: {
      async loadAddress(location) {
        if (!location) {
          console.warn('No location data available. Cannot load address.');
          return;
        }
        try {
          const data = await $.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${
            location.latitude},${location.longitude}&sensor=true&key=${
            this.config.obj_texts.MapApiKey}`);
          console.log('received location data: ', data);
          const comps = data.results[0].address_components;
          this.customer.address = `${comps[1].long_name} ${comps[0].long_name}`;
          // Usually Google returns 5 address components - sometimes 6...
          if (comps[4].long_name === 'Danmark') {
            this.customer.postalCode = comps[5].long_name;
          } else {
            this.customer.postalCode = comps[4].long_name;
          }
          this.customer.city = comps[2].long_name;
        } catch (e) {
          console.log('Error while parsing location components: ', e.message);
        }
      },
      async suggestDistrict() {
        if (this.$store.state.user.districtId !== '') {
          if (this.$store.state.user.districtId) {
            this.customer.districtId = this.$store.state.user.districtId;
          } else {
            console.log('No district found for current user.');
          }
        } else if (this.customer.postalCode !== '') {
          const result = await httpGet(`districts/postalcode/${this.customer.postalCode}`);
          if (result.id) {
            this.customer.districtId = result.id;
          } else {
            console.log(`No district found for postal code ${this.customer.postalCode}`);
          }
        }
      },
      cancel() {
        $('.select_list.add').fadeIn();
        $('#createShop').hide();
        $('.content').animate({ scrollTop: 0 }, 'fast');
        this.$emit('close');
      },
      async save() {
        this.errorMessage = this.validate(this.config.translation);
        if (this.errorMessage !== '') {
          setTimeout(() => { this.errorMessage = ''; }, 3000);
          return;
        }
        if (this.isSubmitting) return;
        this.isSubmitting = true;

        // Indicate that the customer was created in the app. This is used for emailing workflow.
        this.customer.origin = 'app';

        const data = await httpPost('customers', this.customer);

        this.cancel();
        this.customer.id = data.id;
        this.customerId = data.id;
        console.log('selected customer: ', this.customer);
        this.$emit('created', this.customer);
      },
      validate(lang) {
        let errorMessage = '';
        if (this.customer.name === '') {
          errorMessage = lang.missingCustomerName;
        }
        if (this.user.userRole === 'internal') {
          if (this.customer.address === '') {
            errorMessage = lang.missingCustomerAddress;
          }
          if (this.customer.postalCode === '') {
            errorMessage = lang.missingPostalCode;
          }
          if (this.customer.city === '') {
            errorMessage = lang.missingCity;
          }
          if (this.customer.chainId === 'NA') {
            errorMessage = lang.missingChain;
          }
          if (this.customer.districtId === 'NA') {
            errorMessage = lang.missingDistrict;
          }
        }
        return errorMessage;
      },
      createNewCustomer() {
        return {
          name: '',
          address: '',
          postalCode: '',
          city: '',
          phone: '',
          chainId: 'NA',
          districtId: 'NA',
          latitude: 0,
          longitude: 0,
          comment: '',
          userRole: '',
        };
      },
    },
  };
</script>

<style scoped>
  .missingData {
    display: block;
  }

  #createShop ul li .chain-filter {
    margin-bottom: 5px;
    height: 22px;
    width: 50%;
    font-size: 10px;  }

  .filtered-combobox {
    background-color: #FFFFBB;
  }
</style>
